import { VisitorEvent } from './../../models/visitor.event';
import { ConfigService } from './../../../../common/services/config/config.service';
import { GoogleMapService } from './../../services/google.map.service';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { delay, map, Observable } from 'rxjs';
import {
  GoogleMap,
  MapDirectionsService,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { VisitorPlace } from '../../models/visitor.place';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import {
  FancyPlaceDetailsModalComponent
} from 'src/app/modules/place-calendar/components/place.details.modal/fancy.place.details.modal/fancy.place.details.modal.component';
import {
  IPlaceDetailsModalData
} from 'src/app/modules/place-calendar/components/place.details.modal/place.details.modal.provider';
import {
  FancyEventDetailsModalComponent
} from 'src/app/modules/event-calendar/components/event.details.modal/fancy.event.details.modal/fancy.event.details.modal.component';
import {
  IEventDetailsModalData
} from 'src/app/modules/event-calendar/components/event.details.modal/event.details.modal.provider';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { IMapMarkerWithCustomData } from '../../models/place.marker';

@Component({
  selector: 'ig-google-map',
  templateUrl: './google.map.component.html',
  styleUrls: ['./google.map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  @HostBinding('class') hostClass = 'w-full block';
  apiLoaded$: Observable<boolean>;

  _markers: MapMarker[] = [];
  @Input() set markers(value: MapMarker[]) {
    this._markers = value;
    this.fitBounds();
  }

  @Input() zoom = 14;
  _mode: 'light' | 'dark' = 'light';
  @Input() set mode(value: 'light' | 'dark') {
    this._mode = value;
    this.options.mapId = value === 'light'
      ? ConfigService.config.google.mapId
      : ConfigService.config.google.darkModeMapId;
  }

  @Input() useDefaultMarkerClickStyle = true;
  @Input() center: google.maps.LatLngLiteral = null;

  @Output() public onclick = new EventEmitter();
  @Output() public onMarkerClick = new EventEmitter<MapMarker>();

  isShowEventInfoWindow = false;
  isShowPlaceInfoWindow = false;

  options: google.maps.MapOptions;

  @ViewChild(GoogleMap, {static: false}) googleMap: GoogleMap;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;
  @ViewChildren(MapMarker) mapMarkerObjs: QueryList<MapMarker>;

  selectedMarker: {
    markerEle: MapMarker;
    marker: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>;
  };

  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;
  directionsMarkerOptions: google.maps.DirectionsRendererOptions = {
    markerOptions: {
      opacity: 0,
    },
    suppressMarkers: true,
  };

  constructor(
    public googleMapService: GoogleMapService,
    public modalService: ModalService,
    public mapDirectionsService: MapDirectionsService,
    private _placeService: VisitorPlaceService
  ) {
    this.apiLoaded$ = this.googleMapService.loadGoogleMapApi();
  }

  ngOnInit(): void {
    this.options = {
      mapId:
        this._mode === 'light'
          ? ConfigService.config.google.mapId
          : ConfigService.config.google.darkModeMapId,
      center: {lat: 40, lng: -20},
      zoom: this.zoom,
    };

    this.apiLoaded$.pipe(delay(500)).subscribe(() => {
      this.fitBounds();
    });
  }

  mapClick() {
    this.closeInfoWindow();
    this.onclick.emit();
  }

  markerClick(
    markerEle,
    marker: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>
  ) {
    if (this.useDefaultMarkerClickStyle) {
      this._markers.forEach((m) => {
        m.options = {
          icon: null,
          animation: null,
          zIndex: 1,
        };
      });
      marker.options = {
        icon: 'https://iti-images.s3.amazonaws.com/imgs/marker-xs.png',
        animation: google.maps.Animation.BOUNCE,
        zIndex: 2,
      };
      this.center = {
        lat: marker.position.lat,
        lng: marker.position.lng,
      } as google.maps.LatLngLiteral;
    }

    this.selectedMarker = {
      markerEle,
      marker,
    };
    this.onMarkerClick.emit(marker);
  }

  showEventInfoWindow(
    marker?: MapMarker,
    data?: IMapMarkerWithCustomData<VisitorEvent>
  ) {
    this.isShowPlaceInfoWindow = false;
    this.isShowEventInfoWindow = true;
    if (marker && data) {
      this.selectedMarker = {
        markerEle: marker,
        marker: data,
      };
    }
    this.infoWindow.open(this.selectedMarker.markerEle);
  }

  showPlaceInfoWindow(
    marker?: MapMarker,
    data?: IMapMarkerWithCustomData<VisitorPlace>
  ) {
    this.isShowEventInfoWindow = false;
    this.isShowPlaceInfoWindow = true;
    if (marker && data) {
      this.selectedMarker = {
        markerEle: marker,
        marker: data,
      };
    }
    this.infoWindow.open(this.selectedMarker.markerEle);
  }

  closeInfoWindow() {
    this.isShowEventInfoWindow = false;
    this.isShowPlaceInfoWindow = false;
    this.infoWindow?.close();
  }

  goToEvent(event: VisitorEvent) {
    this.modalService.show({
      component: FancyEventDetailsModalComponent,
      panelClass: 'ig-modal-w-full',
      data: {
        currentEvent: event,
        allEvents: [],
        favCustomEvents: [],
        from: 'placeCalendar',
      } as IEventDetailsModalData,
    });
  }

  goToPlace(place: VisitorPlace) {
    console.log('info window click', place);

    if (place.isManuallyCreated || place.socialName) {
      this.modalService.show({
        component: FancyPlaceDetailsModalComponent,
        panelClass: 'ig-modal-w-full',
        data: {
          currentPlace: place,
        } as IPlaceDetailsModalData,
      });
    } else {
      // for the places from TravelBuddy suggestions, some of them are not in ImGoing, but they are searched from Google when TravelBuddy generating the suggestions, show their websites
      if (place.website) {
        window.open(place.website, '_blank');
      }
    }
  }

  async drawDirectionRoute(
    locations: {lat: number; lng: number; stopover?: boolean}[] = [],
    option: {optimizeWaypoints: boolean} = {optimizeWaypoints: false}
  ) {
    if (!locations || locations.length <= 1) return;

    // remove first and last element
    const waypoints = locations.slice(1, -1).map((x) => {
      return {
        location: {lng: x.lng, lat: x.lat},
        stopover: x.stopover ?? true,
      };
    });

    const request: google.maps.DirectionsRequest = {
      destination: locations.last(),
      origin: locations.first(),
      waypoints: waypoints,
      travelMode: google.maps.TravelMode.DRIVING,
      ...option,
    };

    // const request: google.maps.DirectionsRequest = {
    //   destination: {lat: 12, lng: 4},
    //   origin: {lat: 14, lng: 8},
    //   travelMode: google.maps.TravelMode.DRIVING
    // };

    this.directionsResults$ = this.mapDirectionsService
      .route(request)
      .pipe(map((response) => response.result));
    // .subscribe((directionsResults) => {
    //   console.log('directionsResults', directionsResults);
    //   const lat = directionsResults.routes[0].overview_path[0].lat();
    //   const lng = directionsResults.routes[0].overview_path[0].lng();
    //   console.log('lat', lat);
    //   console.log('lng', lng);
    // });

    // this.apiLoaded$.pipe(delay(500)).subscribe(() => {
    //   console.log('locations', locations);

    //   // const request: google.maps.DirectionsRequest = {
    //   //   destination: locations.last(),
    //   //   origin: locations.first(),
    //   //   waypoints: locations.slice(1, -1), // remove first and last element //[{ location: { lat: 13, lng: 6 }, stopover: true }],
    //   //   travelMode: google.maps.TravelMode.DRIVING,
    //   // };

    //   // this.fitBounds();
    // });
  }

  getPixelPositionByLatLng(
    latlng: google.maps.LatLng
  ): Promise<{x: number; y: number}> {
    return new Promise<{x; y}>((resolve, reject) => {
      var overlay = new google.maps.OverlayView();
      overlay.draw = function () {
      };
      overlay.onAdd = function () {
        var projection = this.getProjection();
        var pixel = projection.fromLatLngToContainerPixel(latlng);
        resolve(pixel);
      };
      overlay.setMap(this.googleMap.googleMap);
    });
  }

  getDisplayCategory(place: VisitorPlace) {
    return this._placeService.getDisplayCategory(place);
  }

  setNoContent() {
    this.center = ConfigService.config.google
      .USCenter as google.maps.LatLngLiteral;
    this.options = {
      ...this.options,
      zoom: 5,
    };
  }

  public fitBounds() {
    try {
      if (!window['google']) return;

      if (this._markers.length === 1) {
        this.center = {
          lat: this._markers[0].position.lat as number,
          lng: this._markers[0].position.lng as number,
        } as google.maps.LatLngLiteral;
        return;
      }

      let bounds = new google.maps.LatLngBounds();
      this._markers.forEach((marker: any) => {
        bounds.extend(
          new google.maps.LatLng(marker.position.lat, marker.position.lng)
        );
      });
      this.googleMap?.fitBounds(bounds);
    } catch (error) {
      console.log(error);
    }
  }
}
